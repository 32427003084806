import React from "react";
import SEO from "../components/seo";
import Layout from "../components/layout";
import Header from "../components/Pages/DeviceAnimations/Header/Header";
import Body from "../components/Pages/DeviceAnimations/Body/Body";


export default () => {
  return (
    <Layout>
      <SEO
        title={"3D Device Animations - Animate Phones, Tablets & Laptops"}
        description={
          "Create 3D device animations in your Web Browser. Add motion to your iPhones, Smartphones & Laptop devices. Browse pre-made templates to get started."
        }
        ogDescription={
          "Create 3D Device Animations."
        }
        twitterDescription={
          "Create 3D Device Animations."
        }
      />
      <Header />
      <Body />
    </Layout>
  );
};
