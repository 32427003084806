import React from "react";
import { StaticImage } from "gatsby-plugin-image"
import * as styles from "./header.module.scss";
import TextLoop from "react-text-loop";
import { Templates } from "../../AppPromoVideo/Templates/Templates"



const Hero = () => {

  return (
    <div className={styles.container}>
        <h1>
            <strong>3D Device Animations</strong> Made Simple
        </h1>
        <h3>
            <strong>Animate <span className={styles.textloop}>
                <TextLoop mask={true} 
                    children={["iPhones", "Tablets", "Smartphones", "MacBooks", "Laptops", "iPads", "Phones"]} />
            </span></strong> in a few clicks. Pick your template to get started.
        </h3>
        <Templates />
    </div>
  );
};

export default Hero;
