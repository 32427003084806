import React from "react";
import * as styles from "./body.module.scss";
import { navigate } from "gatsby";



const Section = () => {

    function scrollToElement(id){
        document.getElementById(id).scrollIntoView({
          behavior: 'smooth'
        });
    }

  return (
    <div className={styles.container}>
        <div  className={styles.left}>
            <h4>Device Animations</h4>
            <ul>
                <li onClick={() => scrollToElement("intro")}>Intro</li>
                <li onClick={() => scrollToElement("how-to")}>How to</li>
                <li onClick={() => scrollToElement("use-cases")}>Use cases</li>
                {/*  <li onClick={() => scrollToElement("more")}>More</li> */}
            </ul>
            <a onClick={() => window.location = "/editor/3D"} className={styles.button}>
                Get Started
            </a>
        </div>
        <div  className={styles.right}>
            <div id="intro" className={styles.intro}>
                <h2>
                    Animating Devices
                </h2>
                <p>
                    Device animations make your mockups stand out and help to grab user attention. They are perfect for presenting your application to your audience. Below, we will show you the use cases and how to create such animations.
                </p>
                <h4>Main Features:</h4>
                <ul>
                    <li>
                        12 pre-made scenes
                    </li>
                    <li>
                        Full Customization (Colour, Positioning, Device Types, Motion, Background, Text Effects)
                    </li>
                    <li>
                        Quick & Easy to Use
                    </li>
                    <li>
                        Video Export - MP4, WEBM (transparency support)
                    </li>
                    <li>
                        1080p and 4K resolution
                    </li>
                    <li>
                        All in the Web Browser. No software required.
                    </li>
                </ul>
            </div>
            <div id="how-to" className={styles.howTo}>
                <h2>How to create device animations</h2>
                <p>
                    Watch the video below to see how to create animated mockups.
                </p>
                <div className={styles.video}>
                    <iframe width="560" height="315" src="https://www.youtube-nocookie.com/embed/_0lRVSzGArs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div className={styles.step}>
                    <h4>1. Browse pre-made template</h4>
                    <p>
                        We have prepared a collection of pre-made iPhone animations to get you started. Use the templates above for inspiration or to get started.
                    </p>
                </div>
                <div className={styles.step}>
                    <h4>2. Choose your device</h4>
                    <p>
                        Choose which iPhone, iPad, MacBook, Smartphone & Tablet devices you would like to include in your animation scene.
                    </p>
                </div>
                <div className={styles.step}>
                    <h4>3. Customize your animation</h4>
                    <p>
                        Fully customize the device motion, rotation and positioning by tweaking checkpoints (keyframes). Additionally, dive into more advanced controls to include custom animation and text effects. 
                    </p>
                </div>
                <div className={styles.step}>
                    <h4>4. Render it</h4>
                    <p>
                        Choose between mp4 or webm (with alpha channel) video formats to export your animated mockup. You can also download the mockup as a png sequence to support transparency.
                    </p>
                </div>
            </div>
            <div id="use-cases" className={styles.useCases}>
                <h2>Use Cases</h2>
                <p>Animated devices are very popular in Promo Videos, Advertisements and Presentations as they speak volume to your audience.</p>
                <div className={styles.grid}>
                    <div className={styles.useCase}>
                        <h4>
                            App Promo Videos
                        </h4>
                        <p>App Promo Videos consist of a mix of typography and device motion. Learn more about <a href="/app-promo-video">app promo videos</a> and start generating such animations using Previewed.</p>
                    </div>
                    <div className={styles.useCase}>
                        <h4>
                            Ads
                        </h4>
                        <p>Increase your campaign performance by utilizing a better creative set. Pretty ads get more clicks and better engagement.</p>
                    </div>
                    <div className={styles.useCase}>
                        <h4>
                            Presentations
                        </h4>
                        <p>Whether you are trying to persuade investors or new customers, one important detail is the same: <i>First impression matters</i>. Make it count.</p>
                    </div>
                </div>
            </div>
            <div id="more" className={styles.more}>
            </div>
        </div>
    </div>
  );
};

export default Section;